header.mastheadAbout {    
    height: auto;
    margin-top: 2rem;
    margin-right: 80px;
    margin-left: 80px;
    border-radius: 50px;
    color: #fff;
    background: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.3) 71%, rgba(0,0,0,0.4) 100%), url("../../../public/images/Couple/AdobeStock_224071450.jpeg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    padding-top: 40px;
    padding-bottom: 40px;
}

.herotitleAbout {
    font-size: 40px;
}

.heroSubheadingAbout {
    font-size: 15px;
}

div.row {
    margin-right: 0 !important;
    padding-right: 0 !important;
}



@media screen and (max-width: 576px) {
  .mastheadAbout .row {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  header.mastheadAbout {    
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 430px) {
  header.mastheadAbout h1 {
    font-size: 30px;
  }
  header.mastheadAbout {
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
  }
}