.SignupFormContainer {
    padding: 15vh 140px 140px;
    height: 100vh;
}

.SignupFormContainer .mb-3 p a {
    text-decoration: underline !important;    
}

.SignupFormContainer h2 {
    color: var(--main); 
}

.SignupFormContainer p {
    color: rgba(0, 0, 0, 0.5); 
}

.signupBtn {
    background-color: white;
    color: var(--main);
    border: 1px solid var(--main);    
}
.signupBtn:hover {
    background-color: var(--main);
    border: 1px solid var(--main);    
}

.text-danger {
    font-size: 14px !important;
}


@media screen and (max-width: 767px) {
    .SignupFormContainer {
        padding: 15vh 50px 140px !important;
    }
}


@media screen and (max-width: 575px) {

}


@media screen and (max-width: 430px) and (max-height: 668px) {
    .SignupFormContainer {
        padding: 13vh 50px 140px !important;
    }
    .SignupFormContainer p {
        font-size: 10px;
    }
    .signupInput {
        height: 32px;
    }
    .SignupFormContainer h5 {
        font-size: 15px;
    }
}
