.story {
    background-color: 	#F5F5F5;
    justify-content: center;
    align-items: center;
}
.story2 {
    background-color: 	#F5F5F5;
    justify-content: center;
    align-items: center;
}
.subHeaderLegal {
    color: var(--main);
}
.active .subHeaderLegal {
    color: var(--tertiary);
}

.story .col-lg-6 {
    height: 60vh;
    background: url("../../../public/images/Kids/AdobeStock_476583005.jpeg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
}

.story2 .col-lg-6 {
    height: 60vh;
    background: url("../../../public/images/Estate/AdobeStock_422961960.jpeg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
}

.story2 div .heroBtn1:hover {
    color: black !important;
}

.contactComp-container {
    height: auto;
    margin-left: 5vw !important;
    margin-right: 5vw !important;
    margin-bottom: 15vh;
    padding: 40px;
    background-color: rgba(0, 0, 0, 0.07);
    border-radius: 50px;
}
.contact-text div a {
    color: var(--tertiary);
    text-decoration: none !important;
    font-size: 110%;
    font-weight: 600 !important;
    background-color: var(--secondary);
    padding: 10px 30px;
    border-radius: 30px;
    transition: all ease 300ms;
}
.contact-text div a:hover {
    background-color: var(--tertiary);
    color: var(--main);
}
.aboutPills .active {
    background-color: var(--main) !important;
}

@media screen and (max-width: 991px) {
    .story .col-8 {    
      margin-top: 80px;
    }
    .story2 .col-8 {    
      margin-top: 80px;
      margin-bottom: 80px;
    }
  }