#navCards {
    height: 20vh !important;
    box-shadow: 2px 2px 15px lightgrey;
    transition: transform 1000ms;
    transition: box-shadow 500ms;
    margin: 80px 5px 80px 5px !important;    
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
}

.CardNavTitle {
    color: white !important;
    font-size: 24px;
}


#navCards:hover {
    box-shadow: 5px 5px 12px rgba(0,0,0,0.4);
    border: none;
}

.cardImage {
    height: 30vh;
    width: inherit;
    filter: brightness(70%);
}

.card-text {
    font-size: 12px;
}

.buttonCard {
    transform: box-shadow 1000ms;   
    border: none;
    width: 70%;
}


@media (max-width: 992px) {
    #navCards {
        height: 24vh !important;
    }
    .card-title {
        font-size: 22px;
    }
    .card-text {
        font-size: 12px;
    }
  }

/* @media (max-width: 575px) {    
    #navCards {
        height: 20vh !important;
        margin: 20px 5px 20px 5px !important;    
    }
    .cardBody {
        padding-top: 15% !important;
    }    
  } */

@media (max-width: 767px) {    
    #navCards {
        height: 20vh !important;
        margin: 20px 5px 20px 5px !important;    
    }
    .cardBody {
        padding-top: 15% !important;
    }    
  }

@media (max-width: 400px) {
    #navCards {
        height: 35vh;
    }
    .card-title {
        font-size: 22px;
    }
    .card-text {
        font-size: 11px;
    }
    .button {
        font-size: 13px;
    }
  }

@media screen and (min-height: 685px) {
    #navCards {
        height: 38vh;
    }
  }