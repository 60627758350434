header.masthead404 {    
    height: 62vh;
    color: #fff;
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.34919905462184875) 71%, rgba(255,255,255,0) 100%), url("../../../public/images/lost.jpeg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    padding-top: 7rem;
    padding-bottom: 19rem;
}

.herotitle404 {
    font-size: 40px;
}

.heroSubheading404 {
    font-size: 15px;
}

div.row {
    margin-right: 0 !important;
    padding-right: 0 !important;
}

.Btn404 {
  font-size: 15px;
  margin-top: 10px;
  border-radius: 100px;
  font-weight: bolder;
  background-color: var(--main);
  border: 0.5px solid var(--main);
  transition: transform ease 500ms;
}

.Btn404:hover {
  background-color: transparent;
  border: 0.5px solid white;
  transform: translateY(-1px);
}



  @media screen and (max-width: 575px) {
    header.masthead404 {    
      padding: 40px !important;
      padding-top: 12vh !important;
      height: 60vh;
    }
  }

  @media screen and (max-width: 430px) {
    header.masthead404 {
      height: 65vh;
    }
  }