.startToday-btn {
    width: 20% !important;
    background-color: var(--secondary);
    border-radius: 30px !important;
}


.hiw-video-wrapper {
    height: auto !important;
    max-height: 60vh !important;
    width: 90vw;
    margin-left: 5vw !important;
    position: relative;
    overflow: hidden;    
    border-radius: 50px !important;
}

.hiw-video {
    height: auto !important;
    width: auto !important;
    max-height: 60vh !important;
    border-radius: 50px !important;
    margin: 0px !important;
    padding: 0px !important;
}

.spacing {
    height: 10vh;
}

.video-clickhere {
    color: var(--secondary);
    cursor: pointer;
}
.hiw-video-modal .modal-dialog  {
    margin: auto !important;
}
.hiw-video-modal .modal-dialog .modal-header, .modal-footer, .modal-content  {
    background-color: transparent;
    border: none;            
}
.hiw-info-video {
    margin: auto !important;
    border-radius: 30px !important;
    width: 65%;
}

@media screen and (max-width: 767px) {
    .spacing {
      display: none !important;
    }
  }