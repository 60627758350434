@import url("../../variables.css");

.teamImage {
    max-height: 20vh;
    min-height: 20vh;
    width: auto;
    transition: transform 1s;
    transition: box-shadow 1s;
}
.teamImage:hover {
    transform: translateY(-3px);
    box-shadow: 1px 1px 10px var(--main);
}

.btn-contactTeam {
    border-radius: 50%;
    background-color: var(--main);
    border: none;
    transition: transform 0.2s;
}

.btn-contactTeam:hover {
    border-radius: 50%;
    background-color: white;
    color: black;
    transform: translateY(-3px);
}