#calc {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1066%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='rgba(23, 12, 120, 0.9)'%3e%3c/rect%3e%3cpath d='M 0%2c254 C 57.6%2c214.6 172.8%2c77 288%2c57 C 403.2%2c37 460.8%2c142.2 576%2c154 C 691.2%2c165.8 748.8%2c103.2 864%2c116 C 979.2%2c128.8 1036.8%2c221.8 1152%2c218 C 1267.2%2c214.2 1382.4%2c121.2 1440%2c97L1440 560L0 560z' fill='rgba(23, 12, 120, .5)'%3e%3c/path%3e%3cpath d='M 0%2c339 C 96%2c363.2 288%2c457.4 480%2c460 C 672%2c462.6 768%2c353.6 960%2c352 C 1152%2c350.4 1344%2c432 1440%2c452L1440 560L0 560z' fill='rgba(23, 12, 120, 1)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1066'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
    background-position: center center;
    background-size: cover;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
    /* height: auto; */
    padding-top: 80px;
    width: 90%;
    transition: all ease 2000ms !important;
}

.calcContainerDashboard {
  margin-top: 10vh;
  margin-bottom: 20vh;
}

.disabled {
    background-color: white!important;
    opacity: 100% !important;
    color: black !important;
}

#calc div .mb-3 div .adressInput {
    margin-top: 30px;
    width: 51vw;
    text-align: center;
}
#calc div .mb-3 div .adressInput:focus {
    box-shadow: none !important;
}

.calcBtn {
    background-color: var(--main);
    color: white;
    border: 1px solid white;
}

.calcBtn:hover {
    background-color: white;
    color: black;
    border: 1px solid white;
}
.calcBtn:focus {
    background-color: white;
    color: black;
    border: 1px solid white;
    box-shadow: none;
}
.textContainerCalc {
    height: 9vh !important;
}
.acceptBtnSub {
    background-color: var(--main);
    margin-left: 10px;
    border: 1px solid var(--main);
}
.acceptBtnSub:hover {
    background-color: var(--main);
    margin-left: 10px;
    border: 1px solid var(--main);
}
.acceptBtnSub:focus {
    background-color: var(--main);
    margin-left: 10px;
    border: 1px solid var(--main);
    cursor: progress;
    box-shadow: none !important;
}

.autocompleteBtnFront {  
    width: 51vw;
    text-align: start;
    background-color: white;
    color: black;
    border-radius: 0px !important; 
    border: none;
    border-right: 1px solid var(--main);
    border-left: 1px solid var(--main);
    z-index: 1000;    
    border-top: .2px solid var(--main);
    border-bottom: .2px solid var(--main);
  }

  .disabledBtn {
    display: none !important;
  }
  
  .autocompleteBtnFront:hover {
    background-color: var(--secondary);    
    color: white !important;    
  }
  
  .autocompleteBtnFront:focus {
    background-color: white;
    color: black;
    font-weight: bold;
    border: none;
    border-top: 1px solid gray;
    box-shadow: none !important;
  }
  .showNoneModalContainer {
    width: 30%;
    height: 20%;
    top: 40%;
    left: 35%;
  }
  .showDataModalContainer .modal-dialog .modal-content {
    width: 80vw !important;
    left: 10vw;
    height: 90vh !important;
    top: 2vh;
  }
  .showDataModalContainer .modal-dialog .modal-body {
    padding: 30px;
  }
  .showDataModalHeader {
    background-color: var(--main);
    color: white;
  }
  .modal-footer-text {
    width: 100% !important;
    text-align: right;
  }
  .modal-footer-text p {
    margin: 0;
    font-size: 12px;
  }
  .modal-footer-input {
    justify-content: right;
    width: 100% !important;
  }
  .modal-footer-input input {
    width: calc(4 / 12 * 100vh) !important;
    height: 5vh;
    font-size: 12px;
    margin: 5px;
  }

  .modal-footer-input button {   
    font-size: 12px;
    height: 5vh;
    margin: 5px;
  }

  .modal-footer-input button.col-lg-3 {   
    background-color: var(--main);
    border: 0.5px solid var(--main);
    transition: all ease 500ms;
  }
  .modal-footer-input button.col-lg-3:hover {   
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
    transform: translateY(-1px);
  }

  .modal-footer-input button.col-lg-1 {   
    background-color: white;
    color: var(--main);
  }
  .isDataContainer {
    padding-left: 10vw;
    padding-right: 10vw;
  }
  .isDataContainer p {
    color: rgba(0, 0, 0, 0.6);
    margin: 20px 30px 20px 30px;
    transition: all ease 3000ms;
  }
  .isDataContainer h5 {
    color: var(--main);
    margin: 20px 60px 20px 60px;
  }
  .isDataContainer h3 {
    color: rgba(0, 0, 0, 0.6);
    text-transform: uppercase;
    margin: 20px 60px 20px 60px;
  }
  .infoOld p {
    color: black;
    margin: 20px 60px;
  }

  .resultCalc {
    font-size: 200% !important;
    text-align: center;
  }
  .textCenterMargin {
    text-align: justify;
    text-justify: inter-word;
  }
  .lineDivider {
    color: var(--main) !important;
    font-size: 150%;
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
  .calcSectionHeader {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
  /* .calcBackButton {
    position: absolute !important;
    background-color: black;
    right: 0;
  } */

@media screen and (max-width: 767px) {
    #calc {
        height: 60vh !important;
    }
    .autocompleteBtnFront {
        width: 60vw;
    }
    #calc div .mb-3 div .adressInput {
        margin-top: 70px;
        /* margin-bottom: 20px; */
        width: 60vw;
    }
    .showDataModalContainer .modal-dialog .modal-content {
        width: 90vw !important;
        left: 5vw;
        height: 95vh !important;
        top: 1vh;
    }
    .modal-footer-input input {
        width: calc(12 / 12 * 100vh) !important;
    }
  }

  @media screen and (max-width: 680px) {
    /* #calc {
        height: auto !important;
    } */
    .textContainerCalc {
        height: 12vh !important;
    }
    .showNoneModalContainer {
        width: 50%;
        height: 20%;
        top: 40%;
        left: 25%;
    }
    .isDataContainer {
      padding-left: 5vw;
      padding-right: 5vw;
    }
  }


@media screen and (max-width: 575px) {
    #calc div .mb-3 div .adressInput {
        width: 70vw;
    }
    .autocompleteBtnFront {
        width: 70vw;
    }
    #calc {
        height: auto !important;
    }
    .textContainerCalc {
        height: 18vh !important;
    }
  }


@media screen and (max-width: 430px) {
    #calc div .mb-3 div .adressInput {
        width: 80vw;
    }
    .autocompleteBtnFront {
        width: 80vw;
    }
    .showNoneModalContainer {
        width: 60%;
        height: 20%;
        top: 40%;
        left: 20%;
    }
    .isDataContainer {
      padding-left: 2vw;
      padding-right: 2vw;
    }
    .textContainerCalc {
      height: 25vh !important;
    }
  }

@media screen and (max-width: 430px) and (max-height: 670px) {
    /* #calc {
        height: auto !important;
    } */
  }