#cardNav {
    max-width: 100vw;
    min-width: 100vw;
}

.help h2 {
    color: var(--secondary);
}

.help h3 {
    color: var(--secondary);
}


.suprisesHeading {
    color: var(--secondary);
}
.infographicImg {
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.1) !important;
}

.infoTextContainer1 {
    text-align: end;
    padding-right: 50px;
}
.infoTextContainer2 {
    padding-left: 50px;
}




@media screen and (max-width: 767px) {
    .help {
        padding: 45px 0px 45px 0px !important;
        text-align: center !important;
    }
    .h5responsiveRight {
        padding-top: 50px;
    }
    .BtnresponsiveLeft {
        margin: auto !important;
        margin-bottom: 50px !important;
    }
    .BtnresponsiveRight {
        margin: auto !important;        
    }
    .help .col-xl-9 .row .col-md-5 .heroBtn1 {
        text-align: center !important;        
    }
    .infoTextContainer1 {
        text-align: center !important;
    }
    .infoTextContainer1 {        
        padding-right: 0px !important;
    }
    .infoTextContainer2 {
        padding-left: 0px !important;
    }
  }

@media screen and (max-width: 576px) {
    .help .col-xl-9 .row .col-md-5 .heroBtn1 {
        margin-right: 25% !important;
    }
  }