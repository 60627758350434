header.mastheadEjendomsvurdering {    
    height: auto;
    margin-top: 2rem;
    margin-right: 80px;
    margin-left: 80px;
    border-radius: 50px;
    color: #fff;
    background: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.3) 71%, rgba(0,0,0,0.4) 100%), url("../../../public/images/Estate/AdobeStock_332678068.jpeg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    padding-top: 40px;
    padding-bottom: 40px;
}

.herotitleEjendomsvurdering {
    padding-top: 7vh;
    font-size: 40px;
}

.heroSubheadingEjendomsvurdering {
    font-size: 15px;
}
.calcMeaningCarousel {
    box-shadow: 10px 10px 20px rgba(0,0,0,0.1);
    border-radius: 35px;
    /* height: auto !important; */
}
.carousel-indicators {
    margin: 0 !important;
}
.calcFloatingDesc {
    position: relative;
    top: 46px;
    text-transform: uppercase;
    left: 50px;
    font-size: 20px;
    min-width: 140px;
    max-width: 140px;
    text-align: center;
    color: white;
    background-color: var(--secondary);
    z-index: 10;
}
.calcDescImg {
    border-radius: 35px;
    margin-top: 5vh;
}

.carousel-text {
    height: 25vh !important;
    margin-bottom: 20px;    
}
#vurd_tooltip .tooltip-inner {
    width: 100vw !important;
    background-color: var(--main);
}
#vurd_tooltip .tooltip-arrow {
    display: none;
}



@media screen and (max-width: 767px) {
    .carousel-text {
        height: 25vh !important; 
    }
    header.mastheadEjendomsvurdering {    
        margin-left: 20px;
        margin-right: 20px;
    }
  }

  @media screen and (max-width: 680px) {

  }


@media screen and (max-width: 575px) {
    .calcMeaningCarousel {
        height: 50vh;
    }
  }


@media screen and (max-width: 430px) {
    header.mastheadEjendomsvurdering {
        margin-left: 0;
        margin-right: 0;
        border-radius: 0;
      }
  }