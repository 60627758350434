@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');


header.masthead {
    background-color: rgba(0,0,0,0.04);
    border-radius: 50px;
    margin-right: 30px;
    margin-left: 30px;
    padding-top: 10vh !important;
    padding-bottom: 10vh !important;    
}
.headerNavBackground {
    height: 80px;
    background-color: white;
}
.onboHeading {
    font-size: 140%;    
}
.noMatterP {
    font-size: 160%;
    text-transform: uppercase !important;
    font-weight: 800;
    color: var(--main);
}

.logo img {
    position: absolute;
    top: 26%;
    left: 8.8%;
    width: 25%;
}

.herotitle {
    font-size: 70px;    
    font-family: 'Tenor Sans', sans-serif;
}

.heroSubheading {
    font-size: 14px;
    line-height: 25px;
    /* text-align: justify;
    text-justify: inter-word; */
}

.heroBtn1 {
    background-color: var(--main);
    border: 1px solid transparent;
    padding-left: 25px;
    padding-right: 25px;
}
.heroBtn1 a {
    color: var(--tertiary) !important;
    text-decoration: none;
}
.heroBtn1:hover a {
    color: var(--main) !important;
    text-decoration: none;
}
.heroBtn1:hover {
    border: 1px solid transparent;
    background-color: var(--tertiary);
    color: blue !important;

}
.heroBtn1:focus {
    background-color: transparent;
    border: 1px solid transparent;
}


div.row {
    margin-right: 0 !important;
    padding-right: 0 !important;
}


.scrollDown {
    margin-right: 0 !important;
    padding-right: 0 !important;
    margin-top: 15vh;
    font-size: 15px;
    transition: transform 500ms;
    transition: font-size 500ms;
    
}
.scrollDown:hover {
    transform: translateY(-2px);
    font-size: 17px;
}
  
.scrollDown a {
    color: white;
    text-decoration: none;
}

.offEjendomsvurderinger {
    position: absolute;
    right: 12vw;
    top: 55vh;
    width: 185px;
    height: 185px;
    background-color: white;
    text-align: center;         
    border-radius: 100%;   
    box-shadow: 2px 2px 15px rgba(0,0,0,0.2);
    transition: all ease 500ms;
    font-style: none;
    text-decoration: none;
    color: black;
}
.offEjendomsvurderinger:hover {
    box-shadow: 10px 10px 40px rgba(0,0,0,0.8);
    cursor: pointer;
}
.offSubheading {
    font-size: 80%;
    padding: 0px 20px 0px 20px !important;
    margin: 0 !important;
}
.offHeading {
    padding: 0 !important;
    margin-top: 35px !important;
    margin-bottom: 0 !important;
}
.imgHouseplan {
    max-width: 80%;
    min-width: 80%;
}
.pr {
    padding-right: 5vw;
}

.pl {
    padding-left: 5vw;
}

  @media screen and (max-width: 1261px) {
    .offEjendomsvurderinger {    
        right: 10vw;
        top: 48vh;
    }
  }
  @media screen and (max-width: 991px) {
    header.masthead {
        padding-bottom: 10vh !important;
    }
    .imgHouseplan {
        padding-top: 8vh;
        margin-left: 22vw !important;
    }
    .offEjendomsvurderinger {    
        display: none;
    }
    .imgHouseplan {
        display: none;
    }
  }
  @media screen and (max-width: 840px) {    
/*     .offEjendomsvurderinger {    
        right: 26vw;
        top: 95vh !important;
    } */
  }
  @media screen and (max-width: 767px) {
    .logo img {
        width: 40% !important;
    }
/*     .offEjendomsvurderinger {    
        display: none !important;
    } */
    }

  @media screen and (max-width: 575px) {
    .logo img {
        width: 40% !important;
    }
/*     .herotitle {
        padding-left: 7.5%;
        
    } */
/*     .heroSubheading {
        padding-left: 7.5%;
    } */
    .heroBtn1 {
        margin-left: 20% !important;
        width: 60%;
    }
    .heroBtn2 {
        margin-left: 20% !important;
        width: 60%;
        margin-top: 30px;
    }
    .herotitle {
        padding: 0 !important;        
        font-size: 0px;
        visibility: hidden;
    }
    .onboHeading {
        text-align: center;
    }
    .noMatterP {
        text-align: center;
        font-size: 140%;
    }
    .heroSubheading {
        text-align: center;
    }
    header.masthead {
        margin-top: 40px;
    }
  }


  @media screen and (max-width: 430px) {
    .logo img {
        width: 80% !important;
    }
    .heroSubheading {
        /* padding-left: 7.5%; */
        font-size: 17px;
    }
    .offEjendomsvurderinger {
        position: absolute;
/*         right: 4vw;
        top: 9.5vh; */
    }
  }
  @media screen and (max-width: 430px) and (max-height: 670px) {
    .offEjendomsvurderinger {
        display: none!important;
    }
    header.masthead {
        padding-top: 16rem;
    }
  }
  @media screen and (min-height: 830px) {
    header.masthead {
        padding-top: 21rem;
    }
  }
  /* @media screen and (min-width: 1600px) {
    header.masthead {
        padding-top: 26rem !important;
    }
  } */