.cs-container {
  height: auto;
  padding-bottom: 10vh;
}
.masthead-cs {
  background-color: rgba(0, 0, 0, 0.03);
  margin-right: 40px !important;
  margin-left: 40px !important;
  margin-top: 6vh;
  padding-top: 8vh;
  padding-bottom: 8vh;
  border-radius: 50px;
}

.cs-icons a i {
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
  padding: 10px 15px;
  font-size: 25px;
  min-width: 80px !important;
  border-radius: 100%;
  background-color: white;
  color: var(--secondary);
  box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.1);
  transition: all ease 750ms;
}
.cs-icons a i:hover {
  background-color: var(--secondary);
  color: white;
  box-shadow: none;
}

.mailInputSubscribe input {
  text-align: center;
}
.subscribeBtn {
  margin-top: 5px;
  height: 38px;
  line-height: 38px;
  color: white;
  border-radius: 20px;
  background-color: var(--main) !important;
}
