.cookie-tabs.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: var(--main);
    background-color: transparent;
    border-bottom: 2px solid var(--secondary);
    margin-bottom: 20px;
    border-radius: 0;
}
.cookie-tabs.nav-pills .nav-link, .nav-pills .show > .nav-link {
    color: var(--main);
    background-color: transparent;
    margin-bottom: 20px;
    border-radius: 0;
}

.cookieModal .modal-dialog-centered {
    margin: auto !important;
}

.cookieModal .modal-dialog-centered .modal-content {
    padding: 20px 20px !important;
    border-radius: 20px;    
    background-color: white !important;
}

.cookie-logo {
    width: 20%;
}

.cookie-consent-essential {
    background-color: white;
    color: var(--main);
    border-radius: 10px;
    transition: all ease 500ms;
    padding-top: 10px;
    padding-bottom: 10px;
}
.cookie-consent-all {
    background-color: var(--main);    
    border-radius: 10px;
    transition: all ease 500ms;
    padding-top: 10px;
    padding-bottom: 10px;
}
.cookie-consent-essential:hover {
    background-color: var(--secondary);   
}
.cookie-consent-all:hover  {
    background-color: var(--secondary) !important;
    color: white !important;
}
.cookie-consent-btngroup:hover .cookie-consent-all {
    background-color: white;
    color: var(--main);
}

.cookie-text-p {
    height: 40vh;
    overflow: hidden scroll;
}