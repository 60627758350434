.userCreationContainer {
    background: url("../../../public/images/Estate/AdobeStock_382330271.jpeg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    height: 100vh;
    text-align: center;
}

.userCreationBox {
    height: 60vh;
    background-color: rgba(255, 255, 255, 0.90);
    margin-top: 20vh;
    padding-top: 5vh;
    padding-left: 5vw;
    padding-right: 5vw;
    border-radius: 30px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
}

.circleIndicator {
    width: 12px;
    height: 12px;
    padding: 0;
    background-color: var(--main);
    opacity: 50%;
    margin: 8px;
    border-radius: 20px;
    transition: all ease 500ms !important;
}

.userCreationReturnbtn {
    height: 30px;
    border-radius: 40px;
    padding: 0px;
    margin-right: 10px;
    background-color: white;
    color: var(--main);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
    border: none;
}
.userCreationReturnbtn:hover {
    background-color: var(--main);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
    color: white;
    border: none;
}
.userCreationReturnbtn:focus {
    background-color: var(--main);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
    color: white;
    border: none;
}
.userCreationNextbtn {
    height: 30px;
    border-radius: 40px;
    padding: 0px;
    background-color: var(--secondary);
}
.userCreationNextbtn:hover {    
    background-color: var(--main);
}
.userCreationNextbtn:focus {    
    background-color: var(--main);
    border: none;
    box-shadow: none;
}
.floatBottom {
    position: absolute;
    bottom: 22vh;
}

.ucH1 {
    font-size: 30px;
}
.ucH2 {
    font-size: 25px;
}
.ucH3 {
    font-size: 20px;
    color: var(--main);
    font-weight: 800;
}
.ucP {
    font-size: 14px;
} 
.ucRadioBtn {
    border-radius: 10px;
    background: #F8F8F8;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.10);
    text-align: start;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 8%;
}
.ucCircle {
    height: 25px;
    width: 25px;
    background: #FFF;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.10) inset;
    border-radius: 25px;
    margin-right: 15px;
    float: right;    
}
.ucCircle:hover {
    background-color: var(--secondary);
}


@media screen and (max-width: 767px) {
    .userCreationBox {
        height: 80vh !important;
        margin-top: 15vh !important;
    }
    .floatBottom {
        bottom: 12vh !important;
    }
    .ucNarrowpadding {
        margin-top: 20vh;
    }
  }
@media screen and (max-height: 767px) {
    .userCreationBox {
        height: 80vh !important;
        margin-top: 15vh !important;
    }
    .floatBottom {
        bottom: 12vh !important;
    }
  }