.signupImg {
    height: 100vh;
    padding-top: 10.5rem;
    padding-bottom: 6rem;    
    background: linear-gradient(90deg, rgba(0,0,0,0.02) -25%, rgba(0,0,0,0.34919905462184875) 71%, rgba(255,255,255,0) 100%), url("../../../public/images/Kids/AdobeStock_404611363.jpeg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
}

.signupContainer {
    background-color: rgba(0,0,0,0.02);
    /* background-color: white; */
    color: black !important;
}
