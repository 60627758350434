header.mastheadHowWork {    
    height: auto;
    margin-top: 2rem;
    margin-right: 80px;
    margin-left: 80px;
    border-radius: 50px;
    color: #fff;
    background: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.3) 71%, rgba(0,0,0,0.4) 100%), url("../../../public/images/Estate/AdobeStock_382330271.jpeg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    padding-top: 40px;
    padding-bottom: 40px;
}

.herotitleHowWork {
    font-size: 40px;
}

.heroSubheadingHowWork {
    font-size: 15px;
}

div.row {
    margin-right: 0 !important;
    padding-right: 0 !important;
}

.hiw-container {
  padding: 5vh 5vw 5vh 5vw;
}
.hiw-container-grey {
  padding: 3.5vh 3vw;
  background-color: rgba(0,0,0,0.05);
  height: 27vh;
  margin-right: 5vw;
  margin-left: 5vw !important;
  border-radius: 30px;
  margin-bottom: 20px;  
  
}
.hiw-container-grey2 {
  padding: 3.5vh 0vw 3.5vh 3vw;
  background-color: rgba(0,0,0,0.02);
  height: 27vh;  
/*   border-radius: 30px; */
  margin-bottom: 20px;
  box-shadow: -10em 0em 5em -6em rgba(0,0,0,0.1) inset;
}
.hiw-tabBtn {
  height: 20vh;
  padding: 20px;
  border-radius: 20px;
  cursor: pointer;
}

.hiw-tabBtn-wrapper {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  overflow-x: scroll;
  overflow: -moz-scrollbars-horizontal; 
  height: 20vh;
  box-shadow: -10em 0em 5em -6em rgba(0,0,0,0.1) inset;
  border-radius: 20px;
  white-space: "nowrap";
  scroll-behavior: smooth;
}

.doc-description-text strong {
  font-size: 150%;
  color: var(--main);
}

.doc-description-text {
  padding: 0px 0px 0px 40px;
  font-size: 12px;
}

.doc-btn {
  width: 100%;
  background-color: #fff;
  border: none;
  color: var(--main);
  box-shadow: 0px 0px 0px rgba(0,0,0,0.05), 0px -10px 10px rgba(255,255,255,1);
}
.doc-btn:hover {
  width: 100%;
  background-color: #fff;
  border: none;
  color: var(--main);
  box-shadow: 0px 0px 0px rgba(0,0,0,0.05), 0px -10px 10px rgba(255,255,255,1);
}
.doc-btn:focus {
  width: 100%;
  background-color: #fff;
  border: none;
  color: var(--main);
  box-shadow: 0px 0px 0px rgba(0,0,0,0.05), 0px -10px 10px rgba(255,255,255,1);
}

/* .infographicImg {
  border: 0.5px solid var(--main);  
} */

.hiw-rounded-btn {  
  background-color: var(--secondary);
  color: white;
  text-align: center;
  font-size: 16px;
  margin: 10px;
  min-height: 60px;
  min-width: 60px;
  max-height: 60px;
  max-width: 60px;
  border-radius: 50%;  
  padding-top: 17px;
}
.hiw-arrow {  
  text-align: center;
  font-size: 12px;
  margin: 8px;
  min-height: 20px;
  min-width: 20px;
  max-height: 20px;
  max-width: 20px;  
  padding: 3px 0px 0px 0px;
}
.arrow-left {
  text-align: center;
  padding: 0;
  background-color: white;
  width: 30px;
  font-size: 20px;
  border-radius: 40px;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: all ease 500ms
}
.arrow-right {
  text-align: center;
  padding: 0;
  background-color: white;
  width: 30px;
  font-size: 20px;
  border-radius: 40px;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: all ease 500ms
}
.arrow-left:hover {
  background-color: var(--secondary);
  color: #fff;
}
.arrow-right:hover {
  background-color: var(--secondary);
  color: #fff;
}


@media screen and (max-width: 1481px) {
  .doc-description-text {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 60vw;
    height: 30vh !important;
  }
  .hiw-spacing-1 {
    padding-top: 20vh !important;
  }
}
@media screen and (max-width: 1100px) {
  .hiw-spacing-1 {
    padding-top: 10vh !important;
  }
}
@media screen and (max-width: 991px) {
  .doc-description-text {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 60vw;
    height: 30vh !important;
  }
}
@media screen and (max-width: 575px) {
  header.mastheadHowWork {    
    margin-left: 20px;
    margin-right: 20px;
  }
  .hiw-rounded-btn {    
    margin: 10px;
    min-height: 60px;
    min-width: 60px;
    max-height: 60px;
    max-width: 60px;
  }
  .hiw-arrow {  
    margin: 8px;
    min-height: 20px;
    min-width: 100%;
    max-height: 20px;
    max-width: 100%;
    transform: rotate(90deg);
  }
  .spacing {
    display: none !important;
  }
}
@media screen and (max-width: 430px) {
  header.mastheadHowWork {
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
  }
  .herotitleHowWork  {
    font-size: 30px;
  }
  .heroSubheadingHowWork {
    font-size: 14px;
  }
  .mastheadHowWork div div h3  {
    font-size: 20px;
  }
}