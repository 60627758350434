:root {
    --main: #0C2078;
    /* --main: #607E81; */
    --secondary: rgba(23, 12, 120, 0.9);
    /* --secondary: #A0BEC0; */
    --tertiary: #EDFEB2;
    /* --main: #80A8AB; */
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

p, h1, h2, h3, h4, h5, h6, div {
    font-family: 'Montserrat', sans-serif;
}
button:hover {
    font-weight: 700 !important;
}

i.rounded {
    background-color: var(--secondary);
    color: white;
    padding: 8px 12px;
    border-radius: 20px !important;
}

.text-main {
    color: var(--main);
}

div {
    margin-left: 0 !important;
}

input:focus {
    box-shadow: none !important;
}

.loader-container {
    width: 100% !important;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0 !important; 
    left: 0 !important;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

p {
    color: rgba(0, 0, 0, 0.6);    
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}