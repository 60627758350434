@import url("../../variables.css");

header.mastheadLegal {        
    margin: 7em 40px 1em 40px;
    padding-top: 60px;
    padding-bottom: 60px;
    border-radius: 30px;
    color: rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.08);    
}
.legalImg {
  border-radius: 30px;
}


.herotitleLegal {
    font-size: 40px;
}
.herotitleLegal i {
  color: var(--secondary);
}

.heroSubheadingLegal {
    font-size: 15px;
}

div.row {
    margin-right: 0 !important;
    padding-right: 0 !important;
}

  @media screen and (max-width: 575px) {
    header.mastheadLegal {    
      padding: 40px 20px 40px 20px !important;            
    }
    .herotitleLegal {
      font-size: 25px;
  }
  }

  @media screen and (max-width: 430px) {
    /* header.mastheadLegal {
      height: 65vh;
    } */
  }