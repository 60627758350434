@import url("../variables.css");

  .navContainer {
    margin: 0 !important;
  }

  .brandLogo {
    height: 40px;
    width: auto;
    padding-right: 20px;
  }

  div.dropdown-menu.show {
    position: static;
    display: table;
    border-radius: 0px;
  }

  .dropdown-toggle::after {
    display:none !important; 
  }

  .navbar-toggler {
    border: none;
  }

  .hamburger {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .hamburger .line {
    display: block;
    width: 30px;
    height: 3px;
    margin-block: 7px;
    border-radius: 5px;
    transition: transform .5s;
    opacity: 0.25s;
  }

  .loginBtnHeader {
    border: 1px solid var(--main) !important;
    background-color: var(--main) !important;
    color: white !important;
    border-radius: 10px;
    transition: all ease 1000ms;
  }
  .loginBtnHeader:hover {
    border: 1px solid var(--main) !important;
    background-color: var(--main) !important;
    color: white !important;
  }

  .myHouseBtn {
    margin-right: 20px;    
    border-radius: 10px;
    padding-left: 40px !important;
    padding-right: 40px !important;
    background-color: var(--secondary);
  }

  .hamburger.active .line:nth-child(1) {
    transform: translateY(10px)
    rotate(45deg);
  }
  .hamburger.active .line:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .line:nth-child(3) {
    transform: translateY(-10px)
    rotate(-45deg);
  }

  .loginOffcanvas {
    width: 50% !important;
    height: 55vh;
    top: 22% !important; 
    left: 25%;
    border-radius: 3%;
  }

  .loginOffcanvasBody {
    padding: 0 !important;
    margin: 0!important;
  }

  #mainNavbar {
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
    /* box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1); */
  }

  .loginOffcanvas div .sideImg {
    height: 55vh;
    background: url("../../public/images/Estate/AdobeStock_382330271.jpeg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    border-bottom-right-radius: 5%;
    border-top-right-radius: 5%;
  }

  .loginOffcanvasBody .row .col-xl-6 {
    padding: 30px;
  }

  .closeBtn {
    position: absolute;
    right: -8%;
    background-color: darkslategray;
    border-radius: 70%;
    border: none;
    color: white;
    font-weight: bolder;
    transition: background-color 500ms;
  }
  .closeBtn:hover {
    background-color: gray;
  }

  .loginBtn .heroBtn1:hover {
    color: white !important;
    background-color: var(--main);
    border: 1px solid var(--main) !important;
  }

  .loginBtn:focus {
    background-color: var(--main) !important;
    cursor: progress !important;
    box-shadow: none !important;
  }

  .loginBtn .heroBtn1, .loginBtn .heroBtn2, .signupBtnLogin {
    font-size: 14px;
  }

  .signupBtnLogin {
    color: var(--main);
    background-color: white;
    border: none !important;
    transition: all ease 300ms;
  }

  .signupBtnLogin:hover {
    color: var(--main);
    background-color: white;
    border: 1px solid var(--main) !important;    
  }

  .signupBtnLogin:focus {
    color: var(--main);
    background-color: white;
    border: 1px solid var(--main) !important;    
    box-shadow: none;
  }

  .homeBtn {
    text-align: center !important;
    padding: 10px 0px !important;
  }

  @media screen and (max-width: 991px) {
    #mainNavbar {
      justify-content: center;
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }
/*     .hamburger .line {
      background-color: white !important;
    } */
    .loginBtnHeader {
      text-align: center !important;
    }
    .myHouseBtn {
      text-align: center !important;
      margin: 0px;
      margin-bottom: 10px;
    }
    .navItemColor {
      text-align: center !important;
      padding: 10px 0px 10px 0px !important;
    }        
  }
  @media screen and (min-width: 991px) {
    .homeBtn {
      display: none;
    }
  }


@media screen and (max-width: 767px) {
  .loginOffcanvas {
    width: 60% !important;    
    left: 20%;
  }
}

@media screen and (max-width: 575px) {
  .loginOffcanvas {
    width: 80% !important;    
    left: 10%;
  }
  .loginBtn .heroBtn1, .loginBtn .heroBtn2 {
    margin: 0 !important;
  }
}

@media screen and (max-width: 430px) and (max-height: 667px) {
  .loginOffcanvas {
    height: 70vh;
    top: 15% !important; 
  }
}
