@import url(".././variables.css");

.UpperFooter {
  background-color: var(--secondary);
  color: white;
  padding-top: 50px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
}

.footerCopyrights {
  background-color: var(--main);
  color: white;
}

.footerLogo {
  height: 8vh;
  width: auto;
}

.footerText {
  padding-top: 20px;
  font-size: 14px;
  color: white;
}

.footerLinks {
  list-style: none;
  padding-left: 0px;
  font-size: 14px;
  text-decoration: none;
  color: white;
}

.footerLinks a {
  text-decoration: none;
  color: white;
}

.newsText {
  font-size: 14px;
  color: white;
}

.newsText a {
  text-decoration: none;
  color: white;
}

.copyText {
  font-size: 14px;
  padding-top: 20px;
  color: white;
}

.copyText a {
  text-decoration: none;
  color: white;
}

.copyLink {
  text-decoration: none;
  color: white;
  font-size: 20px;
}

.newsButton {
  background-color: var(--main);
  border: none;
  color: white !important;
  font-size: 26px;
  padding: 10px;
  padding-left: 20px; 
  padding-right: 20px; 
}
.newsButton i {
  color: white !important;
}

.newsButton:hover {
  background-color: var(--main);
}

#mainFooter {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}

.btnLink {
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 50% !important;
  font-size: 14px !important;
  background-color: transparent !important;
  /* border-color: white !important; */
  transition: all ease 500ms;
}

.btnLink:hover {
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
  box-shadow: 5px 5px 5px rgba(0,0,0,0.1);
}

@media screen and (max-width: 575px) {
  .UpperFooter {
    padding-left: 0px;
  }
  .text-center-footer {
    text-align: center;
    margin-top: 20px;
  }  
}