.liFirminfo li {
    list-style: none;
    font-weight: 600;
    padding: 10px;
}

.subHeaderLegal {
    font-size: 120%;
}

.legalTabs {
    margin-top: 5vh !important;
}

@media screen and (max-width: 575px) {
    .subHeaderLegal {
        font-size: 100%;
    }
}
@media screen and (max-width: 767px) {
    .legalTabContent {
        margin-top: 5vh !important;
    }
}